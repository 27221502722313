.container {
  font-family: 'IBM Plex Mono', monospace;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #706d6d;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input,
.address-input,
.button {
  font-family: 'IBM Plex Mono', monospace;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.button, .reset-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold; /* Make text bold */
}

.button:hover, .reset-button:hover {
  background-color: #45a049;
}

.root-display {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.verification-result {
  margin-top: 20px;
}

.reset-button {
  background-color: red;
  color: white;
  font-weight: bold; /* Make text bold */
}

.reset-button:hover {
  background-color: darkred;
}
